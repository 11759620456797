import React from 'react'
import styled from 'styled-components'
import BGIMG from '../../assets/Background.png'
import BubbleBoxText from '../BubbleTextBox'

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  position: relative;
  background-color: ${props => props.theme.body};
  background-image: url(${BGIMG});
`
const Container = styled.div`
  font-size: ${props => props.theme.fontxxxl};
  width: 75%;
  min-height: 70vh;
  margin: 0 auto;
  /* background-color: lightblue; */

  display: flex;
  justify-content: center;
  align-items: center;
`

const TextBoxContainer = styled.div`
  width: 75%;
  margin: auto;
  /* background-color: lightblue; */

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
`

const NewHome = () => {
  return (
      <Section>
        <TextBoxContainer>
            <BubbleBoxText text="Click Me To TEAM"></BubbleBoxText>
            <BubbleBoxText text="Click Me To MINT"></BubbleBoxText>
            <BubbleBoxText text="Click Here For MORE INFO"></BubbleBoxText>
        </TextBoxContainer>

        <Container>
            <h1>TOTOMAN</h1>
        </Container>

        <TextBoxContainer>
            <BubbleBoxText text="Click Me For FAQ"></BubbleBoxText>
            <BubbleBoxText text="Click Me To ROADMAP"></BubbleBoxText>
        </TextBoxContainer>
   </Section>
  )
}

export default NewHome