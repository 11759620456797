import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation, Autoplay, EffectCards } from "swiper";

import img1 from '../assets/Nfts/1.png';
import img2 from '../assets/Nfts/2.png';
import img3 from '../assets/Nfts/3.png';
import img4 from '../assets/Nfts/4.png';
import img5 from '../assets/Nfts/5.png';
import img6 from '../assets/Nfts/6.png';
import img7 from '../assets/Nfts/7.png';
import img8 from '../assets/Nfts/8.png';
import img9 from '../assets/Nfts/9.png';
import img10 from '../assets/Nfts/10.png';

import Arrow from '../assets/Arrow.svg'

const Container = styled.div`
    width: 25vw;
    height: 70vh; //70vh

    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        background-color: ${props => props.theme.carouselColor};
        border-radius: 20px;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .swiper-button-next {
        color: ${props => props.theme.text};
        right: 0;
        width: 4rem;

        background-image: url(${Arrow});
        background-position: center;
        background-size: cover;

        &:after {
            display: none;
        }
    }

    .swiper-button-prev {
        color: ${props => props.theme.text};
        left: 0;
        width: 4rem;
        transform: rotate(180deg);
        background-image: url(${Arrow});
        background-position: center;
        background-size: cover;

        &:after {
            display: none;
        }
    }

    img {
        width: 55vh;
    }
`

const Carousel = () => {
  return (
    <Container>
        <Swiper
            autoplay = {{
                delay: 2000,
                disableOnInteraction: false
            }}
            pagination = {{
                type: 'fraction'
            }}
            scrollbar = {{
                draggable: true
            }}
            navigation = {true}
            effect={"cards"}
            grabCursor={true}
            modules={[EffectCards, Pagination, Navigation, Autoplay]}
            className="mySwiper"
        >
        <SwiperSlide> <img src={img1} alt="TotoMan" /> </SwiperSlide>
        <SwiperSlide> <img src={img2} alt="TotoMan" /> </SwiperSlide>
        <SwiperSlide> <img src={img3} alt="TotoMan" /> </SwiperSlide>
        <SwiperSlide> <img src={img4} alt="TotoMan" /> </SwiperSlide>
        <SwiperSlide> <img src={img5} alt="TotoMan" /> </SwiperSlide>
        <SwiperSlide> <img src={img6} alt="TotoMan" /> </SwiperSlide>
        <SwiperSlide> <img src={img7} alt="TotoMan" /> </SwiperSlide>
        <SwiperSlide> <img src={img8} alt="TotoMan" /> </SwiperSlide>
        <SwiperSlide> <img src={img9} alt="TotoMan" /> </SwiperSlide>
        <SwiperSlide> <img src={img10} alt="TotoMan" /> </SwiperSlide>
      </Swiper>
    </Container>
  )
}

export default Carousel