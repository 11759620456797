import React from 'react'
import styled from 'styled-components'
// import GIF from '../assets/Home Video.mp4'
// import GIF from '../assets/Home GIF.gif'
import GIF from '../assets/Totoman Logo-v1.gif'


const VideoContainer = styled.div`
    width: 75%;

    video {
        width: 100%;
        height: auto;
    }

    img {
        width: 100%;
        height: auto;
    }
`

const CoverVideo = () => {
  return (
    <VideoContainer>
        {/* <video src={GIF} type="video/mp4" autoPlay muted loop/> */}
        <img src={GIF} type="image" alt='NFTs'/>
    </VideoContainer>
  )
}

export default CoverVideo